<template>
	<layout-app class="hd-dashboard">
		<div class="hd-dashboard__content">
			Developers are working hard<br>Coming soon
		</div>
	</layout-app>
</template>

<style lang="scss" scoped>
	.hd-dashboard {
		&__content {
			@include flex-center();
			height: 80vh;
			@include font-size('s');
			text-align: center;
		}
	}
</style>
